import { Pipe, PipeTransform } from '@angular/core';
import { UserSettingsQuery } from 'timeghost-api';

@Pipe({
  name: 'userRef',
  pure: true,
})
export class UserRefPipe implements PipeTransform {
  constructor(private userSettingsQuery: UserSettingsQuery) {}
  transform(value: string | { id: string } | { id: string }[]) {
    const idValue = !value
      ? null
      : typeof value === 'object'
        ? Array.isArray(value)
          ? value.map((x) => x.id)
          : value.id
        : (value as string);
    if (!idValue) return null;
    const user = this.userSettingsQuery.getValue();

    if (Array.isArray(idValue)) return user?.workspace?.users?.filter((x) => idValue.includes(x.id));
    return user?.workspace?.users?.find((x) => x.id === idValue);
  }
}
