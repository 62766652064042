import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs';
import { NotifyService } from 'timeghost-api';
import { fromRxValue } from './_helpers/utils';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AppStateService {
  readonly timecontingentCalculateState = fromRxValue<{ state: 'processing' | 'completed'; users: string[] }>(() =>
    this.signal.onMessage.asObservable().pipe(
      filter((d) => d?.type === 2 && d.payload?.type === 'timecontingent.calculate'),
      map(({ payload }) => payload),
    ),
  );
  constructor(private signal: NotifyService) {}
}
